<template>
  <div class="">
    <TopBarTitleComponent title="Contratos" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonPrimaryComponent
            title="Guardar"
            @onSaveClick="handleSaveClick"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4">
      <b-row>
        <b-col xs="12" sm="12" md="4">
          <section class="input-filters mt-4">
            <InputSearchComponent
              @onChangeSearch="onChangeSearch"
              class="w-100"
            />
          </section>

          <el-tabs v-model="activeTabName">
            <el-tab-pane label="Plantillas" name="templates">
              <WrapperScrollbarComponent
                height="250px"
                v-loading="contractTemplateLoading"
              >
                <div class="card-contents">
                  <div
                    class="card card-shadow card-agreement-templates mb-2 px-2 py-1"
                    v-for="(template, index) in resultQueryTemplates"
                    :key="index"
                    @click="onSelectContract(template)"
                  >
                    <div class="card-body py-3">
                      <b-row>
                        <b-col xs="12" sm="10" md="10">
                          <h5
                            class="h5 font-semi-bold mb-0 mt-2 str-limit-space"
                          >
                            {{ template.name }}
                          </h5>
                        </b-col>
                        <b-col xs="12" sm="2" md="2">
                          <ButtonPlusComponent />
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </WrapperScrollbarComponent>
            </el-tab-pane>
            <el-tab-pane label="Versiones" name="versions">
              <WrapperScrollbarComponent
                height="250px"
                v-loading="contractVersionLoading"
              >
                <div class="card-contents">
                  <div
                    class="card card-shadow card-agreement-versions mb-2 px-2 py-1 str-limit-space"
                    v-for="(version, index) in resultQueryVersions"
                    :key="index"
                    @click="onSelectContract(version)"
                  >
                    <div class="card-body py-3">
                      <b-row>
                        <b-col xs="12" sm="9" md="9">
                          <h5
                            class="h5 font-semi-bold mb-0 mt-2 str-limit-space"
                          >
                            {{ version.name }}
                          </h5>
                        </b-col>
                        <b-col xs="12" sm="3" md="3">
                          <div class="d-flex">
                            <DownloadContractComponent :contract="version" />
                            <ButtonPlusComponent class="ml-3" />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </WrapperScrollbarComponent>
            </el-tab-pane>
          </el-tabs>
        </b-col>
        <b-col xs="12" sm="12" md="8">
          <el-form :model="contract" :rules="contractRules" ref="form">
            <div class="card mt-4">
              <div class="card-body p-3">
                <div class="content-info-agreement" v-if="contract">
                  <el-form-item prop="name" class="mb-4">
                    <el-input
                      placeholder="Nombre del contracto"
                      v-model="contract.name"
                      autocomplete="none"
                      id="input-text-top-bar"
                      class="h2 font-semi-bold"
                    ></el-input>
                  </el-form-item>
                  <EditorWysiwygComponent
                    @onEditorChange="onEditorChange"
                    :setText.sync="contract.description"
                  />
                </div>
                <div
                  class="not-template d-flex justify-content-center align-items-center flex-column text-color-gray"
                  v-else
                >
                  <img :src="fileDocument" alt="File content" width="100px" />
                  <h2 class="h2 font-semi-bold mt-4">Seleccionar Contrato</h2>
                </div>
              </div>
            </div>
          </el-form>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import InputSearchComponent from "../../components/InputSearch";
import ButtonPlusComponent from "../../components/ButtonPlus";
import WrapperScrollbarComponent from "../../components/WrapperScrollbar";
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import EditorWysiwygComponent from "../../components/EditorWysiwyg";
import DownloadContractComponent from "../components/Download";

import fileDocument from "@/assets/img/file-doc-business.svg";
import ContractService from "../services/ContractService";
import Validate from "@/assets/validate";

export default {
  name: "ContractTemplatePage",
  data: () => ({
    searchQuery: null,
    activeTabName: "templates",
    contractTemplateLoading: true,
    contractVersionLoading: true,
    templates: [],
    versions: [],
    contract: null,
    fileDocument,
    contractRules: {
      name: [Validate.rules.required()]
    }
  }),
  computed: {
    resultQueryVersions() {
      if (this.searchQuery) {
        return this.versions.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.versions;
      }
    },
    resultQueryTemplates() {
      if (this.searchQuery) {
        return this.templates.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.templates;
      }
    }
  },
  methods: {
    async getContractTemplates() {
      try {
        let response = await ContractService.getContractTemplates().finally(
          () => {
            this.contractTemplateLoading = false;
          }
        );
        const { success, data } = response.data;
        if (success) {
          data.forEach(d =>
            this.templates.push({
              name: d.name,
              description: d.description
            })
          );
        }
      } catch (error) {
        return false;
      }
    },
    async getContracts() {
      try {
        let response = await ContractService.getContracts().finally(() => {
          this.contractVersionLoading = false;
        });
        const { success, data } = response.data;
        if (success) {
          this.versions = data;
        }
      } catch (error) {
        return false;
      }
    },
    onSelectContract(contract) {
      this.contract = contract;
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await ContractService.addContracts(
              this.contract
            ).finally(() => {
              this.$store.dispatch("toggleRequestLoading");
            });

            const { success, data, message } = response.data;
            if (success) {
              this.onSelectContract(data);
              this.getContracts();
              this.handleTabActive("versions");
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    handleTabActive(name) {
      this.activeTabName = name;
    },
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    onEditorChange({ text }) {
      this.contract.description = text;
    }
  },
  components: {
    TopBarTitleComponent,
    InputSearchComponent,
    ButtonPlusComponent,
    WrapperScrollbarComponent,
    ButtonPrimaryComponent,
    EditorWysiwygComponent,
    DownloadContractComponent
  },
  mounted() {
    this.getContractTemplates();
    this.getContracts();
  }
};
</script>

<style lang="scss" scoped>
.not-template {
  height: 80vh !important;
}
</style>
